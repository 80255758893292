<template>
  <v-dialog v-model="show" width="450">
    <v-card>
      <v-card-title class="headline">Wissel van organisatie</v-card-title>
      <v-card-text>
        <v-select
            label="organisatie"
            item-text="name"
            item-value="id"
            v-model="organisation"
            :items="organisations"></v-select>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="changeOrganisation(organisation)">Wissel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OrganisationPicker',
  props: ['value'],
  watch: {
    show() {
      this.$emit('input', this.show);
    },
    value() {
      this.show = this.value;
    },
  },
  data() {
    return {
      show: this.value,
      organisation: null,
      organisations: [],
    };
  },
  created() {
    this.getOrganisations().then((organisations) => {
      this.organisations = organisations;
    });
  },
  methods: {
    getOrganisations() {
      return new Promise((resolve) => {
        this.$http.get('me/organisation')
            .then((response) => {
              resolve(response.data.data);
            });
      });
    },
    changeOrganisation(id) {
      this.$http.put('me/organisation/current', { id: id }).then(() => {
        location.reload();
      });
    },
  },
};
</script>

<style scoped>

</style>
