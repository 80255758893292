import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
    },
    {
      path: '/oauth/redirect',
      name: 'oauth.redirect',
      component: () => import('@/views/OAuthRedirect'),
    },
    {
      path: '/oauth/callback',
      name: 'oauth.callback',
      component: () => import('@/views/OAuthCallback'),
    },
    {
      path: '/extern/:linkId',
      name: 'extern',
      component: () => import('./views/Extern.vue'),
    },
    {
      path: '/profiel',
      name: 'profile',
      component: () => import('./views/Profile.vue'),
    },
    {
      path: '/contacten',
      name: 'contacts',
      component: () => import('./views/Contacts.vue'),
    },
    {
      path: '/navigatie/:navigationId',
      name: 'subnavigation.show',
      component: () => import('./views/Navigation.vue'),
    },
    {
      path: '/navigatie/:navigationId/documenten',
      name: 'document.index',
      component: () => import('./views/Documents.vue'),
    },
    {
      path: '/news/:newsId',
      name: 'news.show',
      component: () => import('./views/News.vue'),
    },
    {
      path: '/pagina/:pageId/:pageTitle',
      name: 'text.show',
      component: () => import('./views/Page.vue'),
    },
    {
      path: '/no-organisation',
      name: 'no-organisation',
      component: () => import('./views/NoOrganisation.vue'),
    },
  ],
});
