// main.js
import '@fortawesome/fontawesome-pro/css/all.css'; // Ensure you are using css-loader
import Vue from 'vue';
import nl from 'vuetify/es5/locale/nl';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  customProperties: true,
  lang: {
    locales: { nl },
    current: 'nl',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#243164',
        secondary: '#E4E9FF',
        accent: '#243164',
      },
    },
  },
});
