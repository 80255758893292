import VuetifyResource from '@kingscode/vuetify-resource';
import cssVars from 'css-vars-ponyfill';
import Vue from 'vue';
import './moment';

import vuetify from './vuetify';

cssVars();
Vue.use(VuetifyResource);

export { vuetify };
