<template>
  <v-app id="inspire">
    <v-toolbar dense color="primary" height="72px" max-height="72px">
      <v-app-bar-nav-icon @click.stop="handleMenuClick" v-if="canOpenMenu" color="white"></v-app-bar-nav-icon>
      <div v-else style="width: 35px"></div>

      <div class="d-flex justify-space-between" :style="{ width: '100%'}">
        <div class="d-flex justify-center flex-grow-1" >
          <v-tabs
              centered
              icons-and-text
              dark
              background-color="transparent"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab @click="$router.push({name: 'home'})">
              administratie
              <v-icon>far fa-file-alt</v-icon>
            </v-tab>
            <v-tab :to="{name: 'extern', params: {linkId: link.id}}" v-for="(link) in links" :key="link.id">
              {{ link.name }}
              <v-icon>{{ link.icon }}</v-icon>
            </v-tab>
          </v-tabs>
        </div>

        <div class="d-flex align-center ml-2 justify-end overflow-hidden">
          <div class="text-no-wrap mr-2 white--text" v-if="currentOrganisation">
            {{ currentOrganisation.name }}
          </div>
          <v-menu offset-y offset-x>
            <template #activator="{ on }">
              <v-btn
                  color="primary"
                  dark
                  v-on="on"
                  fab
              >
                <v-icon color="white">fa-user</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$router.push({name: 'profile'})">
                <v-list-item-title>Profiel</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push({name: 'contacts'})">
                <v-list-item-title>Contactpersonen</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="organisationsCount > 1" @click="changeOrganisationDialog = true">
                <v-list-item-title>Wissel van organisatie</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title>Uitloggen</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-toolbar>
    <organisation-picker v-model="changeOrganisationDialog"></organisation-picker>
    <v-content class="pa-0 ma-0" justify="center">
      <v-row justify="center" v-if="enableBreadcrumbs" class="ma-0 pa-0">
        <v-breadcrumbs icons divider="<">
          <v-breadcrumbs-item v-for="(crumb, index) in breadcrumbs"
                              :to="breadcrumbRoute(crumb)"
                              :replace="isPage(crumb.navigation_type_id)"
                              :disabled="isPage(crumb.navigation_type_id)"
                              :key="index">
            {{ crumb.name }}
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </v-row>
      <v-row class="ma-0 pa-0" justify="center">
        <v-col cols="12" lg="8">
          <router-view :key="$route.fullPath" :current-organisation="currentOrganisation"></router-view>
        </v-col>
      </v-row>
    </v-content>
    <notify-browser-dialog></notify-browser-dialog>
  </v-app>
</template>

<script>
import NotifyBrowserDialog from './components/NotifyBrowserDialog';
import OrganisationPicker from './components/OrganisationPicker.vue';
import './style.scss';

export default {
  components: {
    NotifyBrowserDialog,
    OrganisationPicker,
  },
  data: () => ({
    drawer: null,
    links: [],
    canOpenMenu: false,
    documentsMenu: false,
    changeOrganisationDialog: false,
    currentOrganisation: null,
    organisationsCount: null,
    breadcrumbs: [],
  }),
  created() {
    this.redirectIfMobile();

    window.addEventListener('message', (event) => {
      if (typeof event.data === 'object' && typeof event.data.type === 'string' && event.data.type === 'canOpenMenu') {
        this.canOpenMenu = true;
      }
    });
    this.getLinks().then((links) => {
      this.links = links;
    });

    this.getOrganisation().then((organisation) => {
      this.currentOrganisation = organisation;
    }).catch(() => {
      this.$router.push({ name: 'no-organisation' });
    });
    this.getOrganisations().then((organisations) => {
      this.organisationsCount = organisations.length;
    });
  },
  watch: {
    '$route': {
      handler() {
        this.canOpenMenu = false;
        this.documentsMenu = false;
        if (this.enableBreadcrumbs) {
          this.getBreadCrumbs();
          this.canOpenMenu = true;
          this.documentsMenu = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    enableBreadcrumbs() {
      return ['navigation.show', 'subnavigation.show', 'document.index'].includes(this.$route.name);
    },
  },
  methods: {
    redirectIfMobile() {
      if (this.isMobile()) {
        window.location = process.env.VUE_APP_FRONT_URL_MOBILE;
      }
    },

    isMobile() {
      var check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

    handleMenuClick() {
      if (this.$route.name === 'extern') {
        let event = new Event('openMenu');
        window.dispatchEvent(event);
      }
    },

    getLinks() {
      return new Promise((resolve) => {
        this.$http.get('me/link').then((response) => {
          resolve(response.data.data);
        });
      });
    },

    logout() {
      /**
       * Because Koenjo doesn't know how he can create a GET URL
       * We have to do some weird shit here to redirect to a POST URL
       */
      let form = document.createElement('form');
      form.method = 'post';
      form.action = process.env.VUE_APP_ROOT_API + 'logout';
      document.body.appendChild(form);
      form.submit();
    },

    getOrganisation() {
      return new Promise((resolve, reject) => {
        this.$http.get('me/organisation/current')
            .then((response) => {
              resolve(response.data.data);
            }).catch((error) => reject(error));
      });
    },

    getOrganisations() {
      return new Promise((resolve) => {
        this.$http.get('me/organisation')
            .then((response) => {
              resolve(response.data.data);
            });
      });
    },
    getCurrentNavigation() {
      return new Promise((resolve) => {
        this.$http.get('navigation/' + this.$route.params.navigationId)
            .then((response) => {
              resolve(response.data.data);
            });
      });
    },
    getBreadCrumbs() {
      this.getCurrentNavigation().then((navigation) => {
        this.breadcrumbs = [];
        this.createBreadCrumbs(navigation);
      });
    },
    createBreadCrumbs(navigation) {
      this.breadcrumbs.unshift({
        navigation_id: navigation.id.toString(),
        name: navigation.name,
        navigation_type_id: navigation.navigation_type_id,
      });

      if (navigation.parent !== null) {
        this.createBreadCrumbs(navigation.parent);
      }
    },
    breadcrumbRoute(navigation) {
      switch (navigation.navigation_type_id) {
        case 1:
          return '/navigatie/' + navigation.navigation_id;
        case 2:
          return '/navigatie/' + navigation.navigation_id + '/documenten';
        default:
          return '';
      }
    },
    isPage(type) {
      return type === 3;
    },
  },
};
</script>

<style>
html {
  overflow: auto;
}

.v-toolbar__content {
  height: auto !important;
}

.v-content {
  padding-top: 72px !important;
}

.page {
  margin: 0 auto;
  max-width: 1200px;
}

.v-slide-group__prev {
  display: none !important;
}
</style>
