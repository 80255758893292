import '@babel/polyfill';
import Vue from 'vue';
import browserDetect from 'vue-browser-detect-plugin';
import API from './API.js';
import App from './App.vue';
import './mixins/filters/date.js';
import { vuetify } from './plugins';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(browserDetect);
Vue.prototype.$http = API;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
