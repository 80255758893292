<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="320"
    >
      <v-card>
        <v-card-title class="headline">Verander u webbrowser</v-card-title>
        <v-card-text>
          <p>
            U maakt gebruik van Internet Explorer. Wij raden u aan om een andere webbrowser te gebruiken.
            Internet Explorer is namelijk een verouderde webbrowser. Hierdoor kan het voorkomen dat bepaalde
            functionaliteit niet goed werkt.
          </p>
          <p>
            U kunt nog verder gaan met deze webbrowser. Wij kunnen helaas een juiste werking van de
            applicatie in Internet Explorer niet garanderen. Voor een optimale ervaring raden wij Chrome,
            Firefox of Edge aan.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="becameNotified" color="blue darken-1" text>Begrepen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as Cookies from 'js-cookie';

export default {
  name: 'NotifyBrowserDialog',
  data: () => {
    return {
      dialog: false,
    };
  },
  computed: {
    isIE() {
      return this.$browserDetect.isIE;
    },
    hasBeenNotified() {
      return Cookies.get('notified_about_browser');
    },
  },
  created() {
    this.dialog = !this.hasBeenNotified && this.isIE;
  },
  methods: {
    becameNotified() {
      Cookies.set('notified_about_browser', true, {
        sameSite: 'None',
        secure: true,
      });
      this.dialog = false;
    },
  },
};
</script>

<style scoped>

</style>
